import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import calculatePercentValueFromTotal from '../../utils/calculate-percent-value-from-total';
import Select from '../select/select';
import NumberInput from './NumberInput';
import Currency from '../formats/currency';
import Percent from '../formats/percent';
import FormGroup from '../../nucleus/form-group/form-group';

const ERROR_MESSAGES = {
  DEFAULT: 'Required',
  INVALID: 'Require: Invalid',
  RANGE: 'Required: Out of Range',
};

const options = [
  {
    display_name: '$',
    value: '$',
  },
  {
    display_name: '%',
    value: '%',
  },
];


// maybe percent and dollar should be set by the name array
const PercentDollarInput = ({
  isZeroValid,
  isPercent,
  baseTotal,
  disabled,
  width,
  isRequired,
  label,
  onChange,
  onBlur,
  onTypeChange,
  min,
  max,
  name,
  maxLength,
  maxPercent,
  maxPercentWarningText,
  percentage,
  value,
}) => {
  const [error, setError] = useState();

  const handleSelectChange = ({ value: selectValue }) => {
    // create a fake event so state is easier to deal with in parent
    // until we can update select to follow html standards
    onTypeChange({
      target: {
        value: selectValue === options[1].value,
        name: name.select,
        type: 'select-one',
      },
      type: 'change',
    });
  };

  const errorMessage = useMemo(() => (
    error || (isRequired && !percentage && !value && ERROR_MESSAGES.DEFAULT) || (isZeroValid && value !== 0) || ''
  ), [isRequired, isZeroValid, percentage, value, error]);

  const handleInputChange = (e) => {
    const update = calculatePercentValueFromTotal(baseTotal, isPercent, e.target.value, name);
    setError();
    onChange({
      target: {
        value: update,
        name: Object.keys(update),
        type: 'text',
      },
      type: 'change',
    });
  };

  const displayMaxPercentWarning = maxPercent && percentage > maxPercent;

  return (
    <FormGroup
      isRequired={isRequired || !!errorMessage}
      size={width}
      label={label}
      hasError={!!errorMessage}
      errorMessage={errorMessage}
      className="percent-dollar-input"
    >
      <React.Fragment>
        <div className={`percent-dollar-container ${displayMaxPercentWarning ? 'warning-border' : ''}`}>
          <Select
            onClear
            defaultOption={isPercent ? options[1] : options[0]}
            onChange={handleSelectChange}
            options={options}
            disabled={disabled}
          />
          <NumberInput
            value={isPercent ? percentage : value}
            precision={isPercent ? 8 : 2}
            onChange={handleInputChange}
            onBlur={(e) => {
              const update = calculatePercentValueFromTotal(baseTotal, isPercent, e.target.value, name);
              onBlur({
                ...e,
                target: {
                  ...e.target,
                  name: Object.keys(update),
                  value: update,
                },
              });
            }}
            disabled={disabled}
            min={min}
            max={max}
            integerPrecision={maxLength ? maxLength - (isPercent ? 8 : 2) : undefined}
            updateInvalidStatus={() => setError(ERROR_MESSAGES.INVALID)}
            updateOutOfRangeStatus={() => setError(ERROR_MESSAGES.RANGE)}
            isZeroValid
          />
        </div>
        <p className="input-hint">
          <span className={`${displayMaxPercentWarning ? 'warning-text' : ''}`}>
            {isPercent
              ? <Currency value={value} emptyState="--" />
              : <Percent value={percentage} emptyState="--" />
            }
            {displayMaxPercentWarning && <><br />{maxPercentWarningText}</>}
          </span>
        </p>
      </React.Fragment>
    </FormGroup>
  );
};

PercentDollarInput.propTypes = {
  name: PropTypes.shape({
    select: PropTypes.string,
    percentage: PropTypes.string,
    value: PropTypes.string,
  }),
  label: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.string,
  ]),
  isRequired: PropTypes.bool,
  isPercent: PropTypes.bool,
  isZeroValid: PropTypes.bool,
  percentage: PropTypes.number,
  value: PropTypes.number,
  baseTotal: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  maxPercent: PropTypes.number,
  maxPercentWarningText: PropTypes.string,
  width: PropTypes.oneOf(['small', 'medium', 'large', 'x-large', '']),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  onTypeChange: PropTypes.func.isRequired,
};

PercentDollarInput.defaultProps = {
  name: {
    select: 'select',
    percentage: 'percentage',
    value: 'value',
  },
  label: '',
  isRequired: false,
  isPercent: false,
  isZeroValid: false,
  percentage: 0,
  value: 0,
  disabled: false,
  min: null,
  max: null,
  maxLength: null,
  maxPercent: null,
  maxPercentWarningText: '',
  width: '',
};

export default PercentDollarInput;
