import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { addInstallment } from '../../../redux/actions/voucher/installments-actions';
import InstallmentItem from './InstallmentItem';
import AddItem from '../../../nucleus/add-item/add-item';

const selectInstallmentIds = state => state.voucherInstallments.map(installment => installment.id);
const selectNextInstallmentNumber = state => state.voucherInstallments.reduce((acc, cur) =>
  (acc > cur.installment_number ? acc : cur.installment_number), 0) + 1;

const Installments = ({
  dealId, isEarlyInvoice, voucherId, showValidation,
}) => {
  const installmentIds = useSelector(selectInstallmentIds, shallowEqual);
  const nextInstallmentNumber = useSelector(selectNextInstallmentNumber);
  const dispatch = useDispatch();

  // if has fetched and should validate and has no installments show error
  return (
    <div className="financials-section-wrapper voucher-installments horizontal-overflow">
      <h3>Installments</h3>
      {!installmentIds.length &&
      <p className="error-message">You must add at least one installment to this {isEarlyInvoice ? 'early invoice' : 'voucher'}</p>}
      {installmentIds.map(id => (
        <InstallmentItem key={id} id={id} dealId={dealId} voucherId={voucherId} showValidation={showValidation} />
        ))}
      <AddItem
        label="Add Installment"
        onClick={() => dispatch(addInstallment({
            transaction: dealId,
            installment_number: nextInstallmentNumber,
            contingencies: [],
          }))}
      />
    </div>
  );
};

Installments.propTypes = {
  dealId: PropTypes.number.isRequired,
  isEarlyInvoice: PropTypes.bool,
  voucherId: PropTypes.number.isRequired,
  showValidation: PropTypes.bool.isRequired,
};

Installments.defaultProps = {
  isEarlyInvoice: false,
};

export default Installments;
